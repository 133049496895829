<template>
  <div>
    <h4
      style="
        margin: auto;
        width: 50%;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
      "
    >
      LIST FPB
      <v-tooltip bottom color="grey">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            text
            icon
            color="grey darken-2"
            @click="refreshData"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>refresh page</span>
      </v-tooltip>
    </h4>
    <v-card
      style="
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 30px;
        padding: 10px;
      "
    >
      <v-alert
        v-if="pendingList.length > 0"
        class="bounce2"
        color="warning"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-alert"
        dense
        style="
          position: fixed;
          top: 75px;
          left: 10px;
          z-index: 1;
          cursor: pointer;
        "
        @click="openPendingDialog"
      >
        Anda memiliki <strong>{{ pendingList.length }}</strong>
        <strong style="margin-left: 5px">FPB pending</strong> yang perlu
        diproses
      </v-alert>
      <v-row no-gutters>
        <v-col cols="12">
          <div
            style="border-radius: 10px 10px 0 0"
            :class="`d-flex justify-start ${
              wWidth < 769 ? 'flex-column' : 'flex-row'
            }`"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <!-- v-if="
                  getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '3'
                  ) !== undefined
                " -->
              <div>
                <v-btn
                  v-if="wWidth > 768"
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text mr-3"
                  style="font-size: 12px"
                  @click="createFpb"
                >
                  Buat FPB
                </v-btn>
                <v-btn
                  v-if="wWidth > 768"
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text"
                  style="font-size: 12px; margin-right: 15px"
                  @click="exportData"
                >
                  Export Data
                </v-btn>
              </div>
            </div>
            <div class="d-flex" style="position: relative; top: 15px">
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Search here"
                type="search"
                outlined
                dense
                append-icon="mdi-magnify"
              ></v-text-field>

              <div>
                <v-select
                  label="Status"
                  style="
                    position: relative;
                    margin-left: 10px;
                    font-size: 12px;
                    height: 40px;
                  "
                  v-model="paramAPI.status"
                  :items="[
                    { id: '', name: 'All' },
                    { id: 0, name: 'Draft' },
                    { id: 1, name: 'Waiting' },
                    { id: 2, name: 'In Progress' },
                    { id: 3, name: 'Close' },
                    { id: 4, name: 'Cancel' },
                    { id: -1, name: 'Reject' },
                    { id: 5, name: 'Pending' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                ></v-select>
              </div>
              <div v-if="wWidth > 768" style="margin-left: 10px">
                <v-autocomplete
                  label="- Cari Project -"
                  v-model="paramAPI.project_id"
                  :items="dropdownProject"
                  @change="changeProject"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  style="margin: 0; height: 42px"
                  clearable
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-start align-center">
            <div
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-right: 10px;
              "
            >
              <v-autocomplete
                v-if="
                  getUserProfile.level.find(({ id }) => id === '41') !==
                  undefined
                "
                label="- Cari di Plant -"
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else
                label="- Cari di Plant -"
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                :disabled="isDisableCompanyDropdown"
                clearable
              ></v-autocomplete>
            </div>
            <div style="height: 42px; margin-top: 5px; margin-bottom: 15px">
              <v-autocomplete
                v-if="
                  getUserProfile.level.find(({ id }) => id === '41') !==
                  undefined
                "
                label="- Cari di Department -"
                v-model="paramAPI.department_id"
                :items="dropdownDepartment"
                @change="changeDepartment"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else
                label="- Cari di Department -"
                v-model="paramAPI.department_id"
                :items="dropdownDepartment"
                @change="changeDepartment"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-autocomplete>
            </div>
            <div
              v-if="wWidth > 768"
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: 10px;
              "
            >
              <v-autocomplete
                label="- Cari Kategori -"
                v-model="paramAPI.category_item"
                :items="dropdownCategoryItem"
                @change="changeCategory"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-autocomplete>
            </div>

            <div
              v-if="wWidth > 768"
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: 10px;
              "
              class="d-flex"
            >
              <v-text-field
                class="ml-1 mr-2"
                dense
                label="Start"
                type="date"
                name="startdate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.request_date_start"
                @change="startDateWatcher"
                outlined
                clearable
              />

              <v-text-field
                class="ml-1 mr-2"
                dense
                label="End"
                type="date"
                name="enddate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.request_date_end"
                @change="endDateWatcher"
                outlined
                clearable
              />
            </div>
            <!-- </div> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="wWidth < 769"
          style="margin-bottom: 13px"
          class="d-flex"
        >
          <v-autocomplete
            label="- Cari Kategori -"
            v-model="paramAPI.category_item"
            :items="dropdownCategoryItem"
            @change="changeCategory"
            item-text="name"
            item-value="id"
            return-id
            outlined
            dense
            style="margin: 0; height: 42px"
            clearable
          ></v-autocomplete>
          <v-autocomplete
            label="- Cari Project -"
            v-model="paramAPI.project_id"
            :items="dropdownProject"
            @change="changeProject"
            item-text="name"
            item-value="id"
            return-id
            outlined
            dense
            style="margin: 0; height: 42px; margin-left: 10px"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          v-if="wWidth < 769"
          style="margin-bottom: 20px"
          class="d-flex"
          ><v-text-field
            class="mr-2"
            dense
            label="Start"
            type="date"
            name="startdate"
            style="height: 30px"
            step="1"
            v-model="paramAPI.request_date_start"
            @change="startDateWatcher"
            outlined
            clearable
          />
          <v-text-field
            class="ml-1"
            dense
            label="End"
            type="date"
            name="enddate"
            style="height: 30px"
            step="1"
            v-model="paramAPI.request_date_end"
            @change="endDateWatcher"
            outlined
            clearable
          />
        </v-col>
        <v-col cols="12" v-if="wWidth < 769" style="margin-bottom: 13px">
          <!-- v-if="
              getUserProfile.level.find(
                ({ id }) => id === '1' || id === '3'
              ) !== undefined
            " -->
          <v-btn
            small
            elevation="1"
            color="indigo"
            class="white--text mb-2"
            style="font-size: 12px; width: 100%; height: 40px"
            @click="createFpb"
          >
            Buat FPB
          </v-btn>
          <v-btn
            small
            elevation="1"
            color="indigo"
            class="white--text"
            style="font-size: 12px; width: 100%; height: 40px"
            @click="exportData"
          >
            Export Data
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            height="50vh"
            mobile-breakpoint="0"
            class="elevation-1"
            style="cursor: pointer"
            :headers="headers"
            :items="result"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            :page="paramAPI.page"
            :items-per-page="paramAPI.itemsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
          >
            <template v-slot:[`item.request_no`]="{ item }">
              <div style="font-size: 13px; font-weight: bolder">
                {{ item.request_no }}
              </div>
            </template>
            <template v-slot:[`item.employee`]="{ item }">
              <div style="font-size: 12px; width: 100px">
                {{ item.employee.name }}
              </div>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              <div style="font-size: 12px; width: 210px">
                {{ item.company.name }}
              </div>
            </template>
            <template v-slot:[`item.department`]="{ item }">
              <div style="font-size: 12px; width: 180px">
                {{ item.department.name }}
              </div>
            </template>
            <template v-slot:[`item.category_item.name`]="{ item }">
              <div style="font-size: 12px; width: 120px">
                {{ item.category_item.name }}
              </div>
            </template>
            <template v-slot:[`item.project.name`]="{ item }">
              <div style="font-size: 12px; width: 120px">
                {{ item.project.name }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                :style="`color: ${colorStatus(
                  item.status
                )}; font-size:13px; width: 90px; font-weight:bolder;`"
              >
                {{ status(item.status) }}
              </div>
            </template>
            <template v-slot:[`item.total_price`]="{ item }">
              <div style="font-size: 12px; width: 85px">
                {{ price(item.total_price) }}
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <div style="font-size: 12px; width: 120px">
                {{ item.created_at }}
              </div>
            </template>
            <template v-slot:[`item.requested_at`]="{ item }">
              <div style="font-size: 12px; width: 70px">
                {{ item.requested_at }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <div style="position: fixed; bottom: 10px; right: 0">
      <Scanner />
    </div>
    <FpbCreate
      @loadPage="initDataTable"
      :wWidth="wWidth"
      :dropdownProject="dropdownProject"
      :dropdownCompany="dropdownCompany"
      :isDisableCompanyDropdown="isDisableCompanyDropdown"
    />

    <v-dialog v-model="pendingDialog" persistent max-width="900px">
      <v-card>
        <v-card-title class="text-h5 black--text warning lighten-2">
          <v-icon color="black" style="margin-right: 5px">mdi-alert</v-icon>
          Segera proses FPB berikut
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-col md="12" style="padding: 0; position: relative; top: 20px">
            <div style="padding: 0 10px">
              <v-data-table
                :headers="pendingHeaders"
                :items="pendingList"
                @click:row="rowClick"
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                hide-default-footer
              >
                <template v-slot:[`item.request_no`]="{ item }">
                  <div style="font-size: 13px; font-weight: bolder">
                    {{ item.request_no }}
                  </div>
                </template>
                <template v-slot:[`item.employee`]="{ item }">
                  <div style="font-size: 12px; width: 120px">
                    {{ item.employee.name }}
                  </div>
                </template>
                <template v-slot:[`item.company`]="{ item }">
                  <div style="font-size: 12px; width: 210px">
                    {{ item.company.name }}
                  </div>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                  <div style="font-size: 12px; width: 180px">
                    {{ item.department.name }}
                  </div>
                </template>
                <template v-slot:[`item.category_item.name`]="{ item }">
                  <div style="font-size: 12px; width: 120px">
                    {{ item.category_item.name }}
                  </div>
                </template>
                <template v-slot:[`item.project.name`]="{ item }">
                  <div style="font-size: 12px; width: 120px">
                    {{ item.project.name }}
                  </div>
                </template>
                <template v-slot:[`item.approved_at`]="{ item }">
                  <div style="font-size: 12px; width: 120px">
                    {{ item.approved_at }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :style="`color: ${colorStatus(
                      item.status
                    )}; font-size:13px; width: 70px; font-weight:bolder;`"
                  >
                    {{ status(item.status) }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="black" text outlined @click="pendingDialog = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  components: {
    FpbCreate: () => import('../../components/eCatalogue/cart/FpbCreate'),
    Scanner: () => import('../home/components/Scanner.vue')
  },
  data: () => ({
    wWidth: window.innerWidth,
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      sortBy: 'request_no',
      sortType: 'desc',
      itemsPerPage: 10,
      company_id: null,
      department_id: null,
      category_item: null,
      project_id: null,
      request_date_start: '',
      request_date_end: ''
    },
    totalData: 0,
    headers: [
      {
        text: 'No. Request',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category_item.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Project',
        value: 'project.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jumlah budget',
        value: 'total_price',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. buat',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. submit',
        value: 'requested_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    pendingHeaders: [
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Approve',
        value: 'approved_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'No. Request',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category_item.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Project',
        value: 'project.name',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    detail: null,
    selected: null,
    loading: true,
    dropdownCompany: [],
    dropdownDepartment: [],
    dropdownProject: [],
    isDisableCompanyDropdown: false,
    url: '',
    pendingDialog: false,
    pendingList: []
  }),
  watch: {
    'paramAPI.sortBy'() {
      this.getDataFromApi()
    },
    'paramAPI.sortType'() {
      this.getDataFromApi()
    },
    'paramAPI.status'() {
      this.getDataFromApi()
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getFpbParam']),
    dropdownCategoryItem() {
      const arr = [
        { id: 1, name: 'Raw Material' },
        { id: 2, name: 'Factory Supply' },
        { id: 3, name: 'Office Supply' },
        { id: 4, name: 'Sparepart' },
        { id: 5, name: 'Jasa' },
        { id: 6, name: 'Maintenance Repair' },
        { id: 7, name: 'Investment / Asset' }
      ]
      if (this.paramAPI.company_id == 5 || this.paramAPI.company_id == 14) {
        arr.splice(
          2,
          4,
          {
            id: 8,
            name: 'Office Supply (< 1jt)'
          },
          {
            id: 9,
            name: 'Office Supply (1jt-5jt)'
          },
          {
            id: 10,
            name: 'Office Supply (> 5j)'
          },
          {
            id: 11,
            name: 'Marketing Material (< 1jt)'
          },
          {
            id: 12,
            name: 'Marketing Material (1jt-5jt)'
          },
          {
            id: 13,
            name: 'Marketing Material (> 5j)'
          },
          {
            id: 14,
            name: 'Sparepart (< 1jt)'
          },
          {
            id: 15,
            name: 'Sparepart (1jt-5jt)'
          },
          {
            id: 16,
            name: 'Sparepart (> 5j)'
          },
          {
            id: 17,
            name: 'Jasa (< 1jt)'
          },
          {
            id: 18,
            name: 'Jasa (1jt-5jt)'
          },
          {
            id: 19,
            name: 'Jasa (> 5j)'
          },
          {
            id: 20,
            name: 'Maintenance Repair (< 1jt)'
          },
          {
            id: 21,
            name: 'Maintenance Repair (1jt-5jt)'
          },
          {
            id: 22,
            name: 'Maintenance Repair (> 5j)'
          }
        )
      }
      return arr
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    if (this.getFpbParam != null) {
      this.paramAPI = this.getFpbParam
    }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()

      // if (
      //   this.getUserProfile.level.find(({ id }) => id == 1 || id == 3) !=
      //   undefined
      // ) {
      await this.getPendingData()
      // }
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    createFpb() {
      // this.initDropdown()
      setTimeout(() => {
        document.getElementById('fpbFormCreate').click()
      }, 500)

      // this.$router.push('/e-catalogue/cart')
    },
    rowClick(pValue, pSlot) {
      // this.$store.commit('setFpbId', pValue.id)
      const arrItem = {
        list: this.result
        // index: pSlot.index
      }
      this.$store.commit('setFpbList', arrItem)
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/fpb-list/fpb/${pValue.id}?idx=${pSlot.index}`
        )
        // this.$router.push('/e-catalogue/fpb-list/fpb')
      }, 200)
    },
    async changeCompany(p) {
      this.paramAPI.company_id = p

      this.clearPaging()
      await this.getDataFromApi()
      await this.getDropdownDepartment()
      await this.getDropdownProject()
      // if (
      //   this.getUserProfile.level.find(({ id }) => id == 1 || id == 3) !=
      //   undefined
      // ) {
      await this.getPendingData()
      // }
    },
    async changeDepartment(p) {
      this.paramAPI.department_id = p
      this.clearPaging()
      await this.getDataFromApi()
    },
    async changeCategory(p) {
      this.paramAPI.category_item = p
      this.clearPaging()
      await this.getDataFromApi()
    },
    async changeProject(p) {
      this.paramAPI.project_id = p
      this.clearPaging()
      await this.getDataFromApi()
    },
    startDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    endDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    async initDropdown() {
      await this.getDropdownCompany()
      await this.getDropdownDepartment()
      await this.getDropdownProject()
    },
    async getDropdownCompany() {
      // console.log(
      //   this.getUserProfile.level.find(
      //     ({ id }) =>
      //       id === '3' ||
      //       id === '14' ||
      //       id === '23' ||
      //       id === '27' ||
      //       id === '41'
      //   )
      // )
      const level = this.getUserProfile.level.find(
        ({ id }) =>
          id === '3' || id === '14' || id === '23' || id === '27' || id === '41'
      )
      console.log('level>>>', level)
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          console.log(
            'user_level>>>',
            level.user_user_level.allowed_company === null ||
              level.user_user_level.allowed_company.length === 0,
            level.user_user_level
          )
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdownCompany = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          console.log('dropdowncompany', res)
          this.dropdownCompany = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getDropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.paramAPI.company_id}}]`
        )
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdownDepartment = res.data.data)
          }
          return (this.dropdownDepartment = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownDepartment = [])
        })
    },
    async getDropdownProject() {
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`
      if (this.paramAPI.company_id !== null) {
        url = url + `&filter=[{"company_id":${this.paramAPI.company_id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdownProject = res.data.data)
          }
          return (this.dropdownProject = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownProject = [])
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = Number(data.total_record)
            return (this.result = data.data)
          } else {
            this.totalData = 0
            return (this.result = [])
          }
          // return this.showMsgDialog('warning', data.status_msg, 'false')
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.loading = false
    },
    initDataTable() {
      let url = `${this.proc}fpb/list?keyword=${this.paramAPI.keyword}&offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}&order_by=${
        this.paramAPI.sortBy
      }&order_type=${this.paramAPI.sortType}&status=${this.paramAPI.status}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      } else {
        url = url + '&department_id='
      }
      if (this.paramAPI.category_item !== null) {
        url = url + `&category_item=${this.paramAPI.category_item}`
      }
      if (this.paramAPI.request_date_start !== null) {
        url = url + `&request_date_start=${this.paramAPI.request_date_start}`
      }
      // else {
      //   url = url + "&request_date_start=''"
      // }
      if (this.paramAPI.request_date_end !== null) {
        url = url + `&request_date_end=${this.paramAPI.request_date_end}`
      }
      //  else {
      //   url = url + "&request_date_end=''"
      // }
      if (this.paramAPI.project_id !== null) {
        url = url + `&project_id=${this.paramAPI.project_id}`
      }
      this.url = url
      this.$store.commit('setFpbItems', this.paramAPI)
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    },
    clearPaging() {
      this.paramAPI.page = 1
      this.paramAPI.offset = 0
      this.paramAPI.limit = 10
      this.paramAPI.itemsPerPage = 10
    },
    status(val) {
      switch (val.id) {
        case -1:
          return 'Rejected'
        case 0:
          return 'Draft'
        case 1:
          return 'Waiting Approval'
        case 2:
          return 'In Progress'
        case 3:
          return 'Close'
        case 4:
          return 'Cancel'
        case 5:
          return 'Pending'
      }
    },
    colorStatus(val) {
      switch (val.id) {
        case -1:
          return 'red'
        case 0:
          return '#1976d2'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        case 3:
          return '#4CAF50'
        case 4:
          return 'red'
        case 5:
          return 'orange'
        // return '#1976d2'
      }
    },
    async exportData() {
      this.showMsgDialog3(
        '',
        'Pilih opsi export',
        true,
        'Export Beserta Detail',
        'Export Tanpa Detail'
      ).then(async (res) => {
        let url = this.url
        if (res.isConfirmed) {
          url = url + '&is_export=true'
        } else {
          url = `${this.proc}fpb/list?keyword=${this.paramAPI.keyword}&offset=0&limit=all&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}&status=${this.paramAPI.status}`

          if (this.paramAPI.company_id !== null) {
            url = url + `&company_id=${this.paramAPI.company_id}`
          }
          if (this.paramAPI.department_id !== null) {
            url = url + `&department_id=${this.paramAPI.department_id}`
          } else {
            url = url + '&department_id='
          }
          if (this.paramAPI.category_item !== null) {
            url = url + `&category_item=${this.paramAPI.category_item}`
          }
          if (this.paramAPI.request_date_start !== null) {
            url =
              url + `&request_date_start=${this.paramAPI.request_date_start}`
          }
          if (this.paramAPI.request_date_end !== null) {
            url = url + `&request_date_end=${this.paramAPI.request_date_end}`
          }
          if (this.paramAPI.project_id !== null) {
            url = url + `&project_id=${this.paramAPI.project_id}`
          }
        }
        // const url = this.url + '&is_export=true'
        await axios
          .get(url)
          .then((res) => {
            console.log(res)
            let selectedData = []
            const response = res.data.data
            if (response !== undefined) {
              for (let i = 0; i < response.length; i++) {
                selectedData.push({
                  request_no: response[i].request_no,
                  employee: response[i].employee.name,
                  department: response[i].department.name,
                  company: response[i].company.name,
                  submit_date: response[i].requested_at,
                  status: response[i].status.name,
                  created_at: response[i].created_at,
                  project: response[i].project,
                  fpb_type_id: response[i].fpb_type_id,
                  fpb_type_name: response[i].fpb_type_name,
                  item: response[i].item !== undefined ? response[i].item : null
                })
              }
              this.exportNow(selectedData)
            } else {
              selectedData = []
            }
            return null
          })
          .catch((error) => {
            console.log(error)
            return null
          })
      })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          request_no: selectedData[i].request_no,
          project_name: selectedData[i].project.name,
          odoo_project_code: selectedData[i].project.odoo_project_code,
          employee: selectedData[i].employee,
          department: selectedData[i].department,
          company: selectedData[i].company,
          submit_date: selectedData[i].submit_date,
          created_at: selectedData[i].created_at,
          status_fpb: selectedData[i].status,
          fpb_type_id: selectedData[i].fpb_type_id,
          fpb_type_name: selectedData[i].fpb_type_name
        }
        if (selectedData[i].item !== null) {
          Object.assign(param, {
            product_code: selectedData[i].item.product_code,
            product_name: selectedData[i].item.product_name,
            qty: selectedData[i].item.qty,
            uom: selectedData[i].item.uom_name,
            quotation_per_unit: selectedData[i].item.quotation_price_per_unit,
            budget_per_unit: selectedData[i].item.budget_price_per_unit,
            budget_total: selectedData[i].item.budget_price_total,
            last_price: selectedData[i].item.last_price,
            date_use: selectedData[i].item.estimate_date_use,
            pr_no: selectedData[i].item.pr_no,
            status_item: selectedData[i].item.status,
            description: selectedData[i].item.description
          })
        }

        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      const date = new Date()
      XLSX.writeFile(wb, `FPB_${date.toLocaleDateString('id')}.xlsx`)
    },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    },
    showMsgDialog3(pModalType, pStatusMsg, pBtnCancel, yesBtn, noBtn, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: yesBtn,
            cancelButtonText: noBtn !== '' ? noBtn : 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async getPendingData() {
      let url = `${this.proc}fpb/list?offset=0&limit=1000&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}&status=5&pending_notif=1`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      // this.url = url
      axios
        .get(url)
        .then((res) => {
          console.log('pendingList', res)
          if (res.data.status_code === '00') {
            for (let i = 0; i < res.data.data.length; i++) {
              res.data.data[i].approved_at = this.convertDate(
                res.data.data[i].approved_at
              )
            }
            this.pendingList = res.data.data
            if (this.pendingList.length > 0) {
              setTimeout(() => {
                this.openPendingDialog()
              }, 300)
            }
            return null
          } else {
            return (this.pendingList = [])
          }
        })
        .catch((err) => {
          console.log(err)
          return (this.pendingList = [])
        })
    },
    openPendingDialog() {
      setTimeout(() => {
        this.pendingDialog = true
      }, 300)
    },
    refreshData() {
      this.getDataFromApi()
      this.getPendingData()
    },
    convertDate(val) {
      const raw = val
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vendorSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-7px);
  }
}
</style>
